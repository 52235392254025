import React from "react";
import {
  ColumnLinks,
  ColumnTitle,
  FooterBrandText,
  FooterColumn,
  FooterHref,
  FooterLeft,
  FooterRight,
} from "./LeftDiv";
import { currentWebsiteURL } from "../data/config";
import { FooterCont, SocialIconsDiv } from "../data/css/FooterStyles";
import {
  discord_svg,
  medium_svg,
  telegram_svg,
  twitter_svg,
} from "../data/svgs";
import config from "../data/config.json";

const Footer = () => {
  return (
    <FooterCont>
      <FooterLeft>
        <FooterBrandText>WaxFusion</FooterBrandText>
      </FooterLeft>

      <FooterRight>
        <FooterColumn>
          <ColumnTitle>Ecosystem</ColumnTitle>
          <ColumnLinks>
            <FooterHref href={`${currentWebsiteURL}/stake`}>
              Liquid Staking
            </FooterHref>
            <br />
            <FooterHref href={`${currentWebsiteURL}/rent`}>
              Short CPU Rental
            </FooterHref>
            <br />
            <FooterHref href={`${currentWebsiteURL}/long-term-rent`}>
              Long CPU Rental
            </FooterHref>
            <br />
            <FooterHref href={`${currentWebsiteURL}/powerup`}>
              PowerUp
            </FooterHref>
            <br />
            <FooterHref href={`${config.URLs.coingecko}`} target="none">
              CoinGecko
            </FooterHref>            
            <br />
            <FooterHref href={`${config.URLs.defillama}`} target="none">
              DefiLlama
            </FooterHref>
            <br />
            <FooterHref href={`${config.URLs.dappradar}`} target="none">
              DappRadar
            </FooterHref>
            <br />                        
          </ColumnLinks>
        </FooterColumn>

        <FooterColumn>
          <ColumnTitle>Support</ColumnTitle>
          <ColumnLinks>
            <FooterHref href={config.URLs.docs} target="none">
              Documentation
            </FooterHref>
            <br />
            <FooterHref href={config.URLs.discord} target="none">
              Discord
            </FooterHref>
            <br />
            <FooterHref href={config.URLs.telegram} target="none">
              Telegram
            </FooterHref>
            <br />
            <FooterHref href={`${currentWebsiteURL}/status`}>
              Status
            </FooterHref>
            <br />            
          </ColumnLinks>
        </FooterColumn>
      </FooterRight>

      <SocialIconsDiv>
        {/* Telegram */}
        <a href={config.URLs.telegram} target="none">
          {telegram_svg}
        </a>

        {/* Twitter */}
        <a href={config.URLs.twitter} target="none">
          {twitter_svg}
        </a>

        {/* Discord */}
        <a href={config.URLs.discord} target="none">
          {discord_svg}
        </a>

        {/* Medium */}
        <a href={config.URLs.medium} target="none">
          {medium_svg}
        </a>
      </SocialIconsDiv>
    </FooterCont>
  );
};

export default Footer;
